import { Component, Prop, Ref, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { FlatPickr } from '@/components/bootstrap'
import { IVmodelProps } from '@/interfaces/IVmodelProps'
import '@core/scss/vue/libs/vue-flatpicker.scss'

interface IDatePickerProps extends IVmodelProps<string> {
  dateFormat?: string
  displayedFormat?: string
  enableTime?: boolean
  range?: boolean
  disabled?: boolean
  placeholder?: string

  onChange?: (dates: Date[]) => void
}

@Component
export default class DateTimePicker extends tsx.Component<IDatePickerProps> {
  @Ref() readonly flatPickr!: { $el: { _flatpickr: { open: () => void } } }

  @VModel({ required: true })
  dateTime!: string

  @Prop({ type: String, default: 'Y-m-d' })
  readonly dateFormat!: string
  @Prop({ type: String })
  readonly displayedFormat?: string
  @Prop({ type: Boolean, default: false })
  readonly enableTime?: boolean
  @Prop({ type: Boolean, default: false })
  readonly range?: boolean
  @Prop({ type: Boolean, default: false })
  readonly disabled?: boolean
  @Prop({ type: String })
  readonly placeholder?: string

  onChange(dates: Date[]) {
    if (this.range) {
      if (dates.length !== 1) {
        this.$emit('change', dates)
      }
    } else {
      this.$emit('change', dates)
    }
  }

  open() {
    this.flatPickr.$el._flatpickr.open()
  }

  protected render() {
    return (
      <FlatPickr
        ref="flatPickr"
        value={this.dateTime}
        onInput={value => (this.dateTime = value)}
        disabled={this.disabled}
        placeholder={this.placeholder}
        config={{
          locale: Russian,
          dateFormat: this.dateFormat,
          altInput: !!this.displayedFormat,
          altFormat: this.displayedFormat,
          enableTime: this.enableTime,
          time_24hr: true,
          mode: this.range ? 'range' : 'single',
          onChange: [this.onChange],
        }}
        class="flatpickr-input"
      />
    )
  }
}
